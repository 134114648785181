<style scoped>
	.real-amount {
		text-align: center;
	    background: #4a97f7;
	    padding: 10px;
	    border-radius: 15px;
	    font-weight: bold;
	    color: #fff;
	    font-size: 17px;
	}
	.text-real-amount {
		font-size: 14px;
		letter-spacing: 1px;
	}
</style>

<template>
	<div>
		<div class="row">
			<div class="col-lg-12" v-if="settings.card_status === 1">
				<div class="panel panel-primary">
				    <div class="panel-body">
				        <h3 class="panel-title">
				        	<inline-svg :src="require('@/assets/images/icons/card.svg')" class="panel-icon" alt="" />
				        	Nạp thẻ cào
				        </h3>

				        <Notification :data="noti" :key="k" v-for="(noti, k) in notification.payment_card"></Notification>

				        <form action="" @submit.prevent="chargingCard">
				        	<div class="form-group">
				        		<label>Nhà mạng :</label>
				        		<select class="form-control" v-model="formCard.telco" @change="selectCard" required="">
				        			<option v-for="(telco, k, index) in cardLists" :value="k" :selected="index === 0">Thẻ {{ k }} ( {{ telco[0].discount }}% )</option>
				        		</select>
				        	</div>
				        	<div class="form-group">
				        		<label>Mệnh giá :</label>
				        		<select class="form-control" v-model="formCard.amount" required="" @change="selectAmount">
				        			<option v-for="(price, k) in cardPriceLists" :value="price.amount" :selected="k === 0">{{ price.amount | numberFormat }} VND - Chiết khấu {{ price.discount }}%</option>
				        		</select>
				        	</div>
				        	<div class="form-group">
				        		<label>Mã Serial :</label>
				        		<input type="text" class="form-control" v-model="formCard.serial" placeholder="Nhập mã Serial ở hàng đầu tiên của thẻ cào" required="">
				        	</div>
				        	<div class="form-group">
				        		<label>Mã Thẻ :</label>
				        		<input type="text" class="form-control" v-model="formCard.code" placeholder="Nhập mã thẻ cào sau lớp bạc mỏng" required="">
				        	</div>
				        	<div class="form-group">
				        		<label>Số tiền thực nhận :</label>
				        		<div class="real-amount">
					        		{{ realAmount | numberFormat }} {{ settings.currency }}
					        		<div class="text-real-amount">{{ textRealAmount }}</div>
					        	</div>
				        	</div>
				        	<div class="alert alert-danger text-center" v-if="cardMessage"><span v-html="cardMessage"></span></div>
				        	<button class="btn b-success btn-block">Nạp thẻ cào</button>
				        </form>
				    </div>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="panel panel-primary">
				    <div class="panel-body">
				        <h3 class="panel-title">
				        	<inline-svg :src="navbarInfo.icon" class="panel-icon" alt="" />
				        	Nạp tiền ngân hàng
				        </h3>
				        

				        <Notification :data="noti" :key="k" v-for="(noti, k) in notification.payment"></Notification>

				        <div class="row">
				        	<div class="col-lg-6" v-if="pm.status === 1" v-for="pm in payment">
				        		<div class="payment-info">
				        			<div class="logo pb-3" v-if="pm.logo">
				        				<img :src="pm.logo" alt="">
				        			</div>
				        			<div class="content" v-html="pm.info"></div>
				        		</div>
				        	</div>
				        </div>


				        <div class="text-center content-transfer-label">NỘI DUNG NẠP TIỀN</div>
				        <div class="content-transfer bg-main">{{ content_transfer }} 
				        	<br>
				        	<button class="btn btn-danger b-danger btn-sm" @click="copyTrans"><i class="fa fa-copy"></i> Copy</button>
				        </div>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import '@/assets/css/payment.css';

	export default {
		data () {
			return {
				formCard: {
					telco: 'VIETTEL',
					amount: 10000,
					action: 'send'
				},
				cardMessage: '',
				cardLists: {},
				textRealAmount: '',
				cardPriceLists: [],
				realAmount: '',
				textRealAmount: '',
			}
		},
		props: ['payment', 'user', 'settings', 'navbarInfo', 'site', 'notification'],
		created () {
			let vm = this;
			jQuery(($) => {
				$('[data-copy]').bind('click', function () {
					let $text = $(this).data('copy');
					vm.copy($text);
				});
			});
			this.getCard();
		},
		computed: {
			content_transfer () {
				let content = this.settings.content_payment;
				if (!content) {
					content = '{username} nap tien ' + this.site.name;
				}
				content = content.replace('{username}', this.user.username);
				return content;
			}
		},
		methods: {
			selectCard () {
				this.cardPriceLists = this.cardLists[this.formCard.telco];
				this.formCard.amount = this.cardPriceLists[0].amount;
				this.selectAmount();
			},
			selectAmount () {
				this.realAmount = 0;
				let cardData = this.cardPriceLists.filter(x => x.amount === this.formCard.amount)[0];
				this.realAmount = (cardData.amount - cardData.amount * cardData.discount / 100);
				this.textRealAmount = 'Số tiền thực nhận khi nạp thẻ ' + this.formCard.telco + ' mệnh giá '+ this.$options.filters.numberFormat(cardData.amount) +' VND ( '+ cardData.discount +'% )';
			},
			getCard () {
				this.$http.post('card?action=prices').then(res => {
					if (res.body.status != 0) {
						this.cardLists = res.body;
						this.formCard.telco = Object.keys(this.cardLists)[0];
						this.selectCard();
					}
				});
			},
			chargingCard () {
				this.$swal({
					title: 'Chắc chắn?',
					html: 'Bạn đã chắc chắn chọn đúng <b>Nhà mạng</b> và <b>Mệnh giá</b> ? Nếu sai có thể sẽ mất thẻ cào hoặc bị trừ chiết khấu cao!',
					confirmButtonText: 'Nạp thẻ',
					cancelButtonText: 'Kiểm tra lại',
					showCancelButton: 1,
					imageUrl: require('@/assets/images/icons/card.svg'),
					imageHeight: '100px'
				}).then((isConfirm) => {
					if (isConfirm.value) {
						this.cardMessage = '';
						this.$http.post('card', this.formCard).then(res => {
							this.cardMessage = '<i class="fa fa-warning"></i> ' + res.body.message;
							this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
						});
					}
				});
			},
			copyTrans () {
				this.$copyText(this.content_transfer).then(() => {
					this.$swal('Thông báo', '< <b>'+ this.content_transfer +'</b> > <br> Đã sao chép nội dung nạp tiền <br> Vui lòng chuyển khoản đúng cú pháp!', 'success');
				});
			},
			copy (t) {
				this.$copyText(t).then(() => {
					this.$swal('Thông báo', 'Copy thành công <b>'+ t +'</b>', 'success');
				});
			}
		}
	}
</script>